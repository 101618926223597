.country-code-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 394px;
  height: 600px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow: visible !important;
}

.country-code-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.country-code-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.country-code-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: start;
  color: #2c2c2e;
}

[dir="ltr"] .country-code-heading {
  width: 60%;
}

[dir="rtl"] .country-code-heading {
  width: 50%;
}

.country-code-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 385px;
  width: 99%;
  scrollbar-color: #757575 #e5e5ea !important;
  scrollbar-width: thin;
}

.country-code-list-container::-webkit-scrollbar-track {
  border-radius: 3px !important;
}

.country-code-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.country-detail-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}

.all-countries-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #48484a;
}

.country-name-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2c2c2e;
  width: 80%;
  margin-top: 11px;
  margin-bottom: 11px;
}

.country-code-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2c2c2e;
  text-align: right;
  width: 20%;
  margin-top: 11px;
  margin-bottom: 11px;
}

.search-country-code-input-style {
  margin: auto !important;
  width: 360px !important;
  color: #48484a !important;
  border-radius: 24px !important;
  height: 48px !important;
  margin-top: 10px !important;
}